exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-develop-js": () => import("./../../../src/pages/develop.js" /* webpackChunkName: "component---src-pages-develop-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rfid-js": () => import("./../../../src/pages/rfid.js" /* webpackChunkName: "component---src-pages-rfid-js" */),
  "component---src-pages-rpa-js": () => import("./../../../src/pages/rpa.js" /* webpackChunkName: "component---src-pages-rpa-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */)
}

